<template>
  <div id="page-personnel-edit">
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Personnels" icon-pack="feather" icon="icon-users">
            <div class="tab-text">
              <personnel-list-tab></personnel-list-tab>
            </div>
          </vs-tab>
          <vs-tab label="Nouveau" icon-pack="feather" icon="icon-edit">
            <div class="tab-text">
              <personnel-new-tab></personnel-new-tab>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import PersonnelNewTab from './PersonnelNew.vue'
import PersonnelListTab from './PersonnelList.vue'
export default {
  name: 'PersonnelView',
  components: {
    PersonnelNewTab,
    PersonnelListTab
  },
  data () {
    return {
      activeTab: 0
    }
  }
}
</script>

<style scoped>

</style>
