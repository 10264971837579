<template>
  <span>{{ params.EmailPersonel }}</span>
</template>

<script>
export default {
  name: 'CellRendererEmail',
  computed: {
  }
}
</script>
