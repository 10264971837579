<template>
  <span>{{ params.TelPersonel }}</span>
</template>

<script>
export default {
  name: 'CellRendererTel',
  computed: {
  }
}
</script>
